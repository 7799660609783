





import Vue, { PropType } from "vue";
import { ChartRecord } from "./Chart";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const colors = [
  "#4dc9f6",
  "#f67019",
  "#f53794",
  "#537bc4",
  "#acc236",
  "#166a8f",
  "#00a950",
  "#58595b",
  "#8549ba",
];

export default Vue.extend({
  props: {
    width: {
      required: false,
      type: Number,
      default: 400
    },
    height: {
      required: false,
      type: Number,
      default: 400
    },
    chartData: {
      required: true,
      type: Array as PropType<Array<ChartRecord>>,
    },
  },
  data() {
    return {
      chart: null as null | Chart,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(n, o) {
        if (!this.chart) return;
        (this.chart.data.labels = this.chartData.map((x) => x.label)),
          (this.chart.data.datasets = [
            {
              backgroundColor: colors,
              data: this.chartData.map((x) => x.value),
            },
          ]);
        this.chart.update();
      },
    },
  },
  mounted() {
    this.chart = new Chart(this.$refs.canvas as HTMLCanvasElement, {
      type: "pie",
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            display: false
          },
          title: {
            display: false,
          },
        },
      },
      data: {
        labels: this.chartData.map((x) => x.label),
        datasets: [
          {
            backgroundColor: colors,
            data: this.chartData.map((x) => x.value),
          },
        ],
      },
    });
  },
});
