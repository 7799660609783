
























































































































































































































































































































import Vue from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import Chart from "@/components/Chart.vue";
import { ChartRecord } from "@/components/Chart";
import { ValidationObserver } from "vee-validate";
import {
  getStakeholdersOfCompany,
  StakeholderDto,
} from "@/service/stakeholders/stakeholders.service";
import { ShareClassDto } from "@/service/share-classes/share-classes.service";
import { SharesIssuanceDto } from "@/service/shares-issuances/shares-issuances.service.dto";
import { ExcelProp, Grid } from "@/components/grid";

interface ShareholderTableRecord {
  stakeholderName: string;
  stakeholderId: string;
  shares: number;
  ownershipPercent: number;
  canVote: boolean;
}

interface OptionHolderTableRecord {
  stakeholderName: string;
  stakeholderId: string;
  options: number;
  ownershipPercent: number;
}

interface SharesTableRecord {
  className: string;
  shares: number;
  percent: number;
}

export default Vue.extend({
  components: {
    Chart,
    Grid,
  },
  data() {
    return {
      loading: false,
      sharePrice: companiesStore.state.current?.sharePrice ?? 0,
      viewVotingShareholdersOnly: false,
      simulateOptionsExercised: false,
      simulateNewIssuance: false,
      rawDataHolders: [] as Array<StakeholderDto>,
      newIssuance: {
        holderName: "",
        sharesCount: undefined as number | undefined,
        sharesClassId: "",
      },
      simulatedHolders: [] as Array<StakeholderDto>,
      shareholdersTableHeaders: [
        {
          text: this.$t("name").toString(),
          align: "start",
          value: "stakeholderName",
        },
        {
          text: this.$t("shares").toString(),
          align: "start",
          value: "shares",
        },
        {
          text: this.$t("totalOwnership").toString(),
          align: "start",
          value: "ownershipPercent",
        },
      ],
      shareholdersTableHeadersExcelOptions: {
        filename: "share-holders",
        fields: ["stakeholderName", "shares", "ownershipPercent"],
        transformer: (rows) => {
          return rows.map((row) => {
            switch (row.fieldName) {
              case "stakeholderName":
                row.fieldName = this.$t('name').toString()
                break;
              case "ownershipPercent":
                row.fieldName = this.$t('totalOwnership').toString()
                row.fieldValues = row.fieldValues.map((val) =>
                  this.$n(parseFloat(val)) + '%'
                );
                break;
              case "shares":
                row.fieldName = this.$t('shares').toString()
                row.fieldValues = row.fieldValues.map((val) =>
                  this.$n(parseFloat(val))
                );
                break;
            }

            return row;
          });
        },
      } as ExcelProp,
      optionHoldersTableHeaders: [
        {
          text: this.$t("name").toString(),
          align: "start",
          value: "stakeholderName",
        },
        {
          text: this.$t("options").toString(),
          align: "start",
          value: "options",
        },
        {
          text: this.$t("totalOwnership").toString(),
          align: "start",
          value: "ownershipPercent",
        },
      ],
      optionHoldersTableHeadersExcelOptions: {
        filename: "option-holders",
        fields: ["stakeholderName", "options", "ownershipPercent"],
        transformer: (rows) => {
          return rows.map((row) => {
            switch (row.fieldName) {
              case "stakeholderName":
                row.fieldName = this.$t('name').toString()
                break;
              case "ownershipPercent":
                row.fieldName = this.$t('totalOwnership').toString()
                row.fieldValues = row.fieldValues.map((val) =>
                  this.$n(parseFloat(val)) + '%'
                );
                break;
              case "options":
                row.fieldName = this.$t('options').toString()
                row.fieldValues = row.fieldValues.map((val) =>
                  this.$n(parseFloat(val))
                );
                break;
            }

            return row;
          });
        },
      } as ExcelProp,
      headersShares: [
        {
          text: this.$t("className").toString(),
          align: "start",
          sortable: false,
          value: "className",
        },
        {
          text: this.$t("shares").toString(),
          align: "start",
          sortable: false,
          value: "shares",
        },
        {
          text: this.$t("percentage").toString(),
          align: "start",
          sortable: false,
          value: "percent",
        },
      ],
    };
  },
  computed: {
    areSimulationOptionsOn(): boolean {
      return this.simulateOptionsExercised || this.simulateNewIssuance;
    },
    shareholdersChartData(): Array<ChartRecord> {
      return this.shareholdersTableData.map((x) => ({
        label: x.stakeholderName,
        value: x.shares,
      }));
    },
    companyShares(): number {
      let result = this.shareholders.reduce((acc, x) => {
        return acc + this.getShares(x);
      }, 0);

      return result;
    },
    shareholders(): Array<StakeholderDto> {
      var result = this.rawDataHolders;
      if (this.simulateNewIssuance)
        result = [...this.simulatedHolders, ...result];

      return result.filter(
        (x) =>
          (x.sharesTotal > 0 || (this.simulateOptionsExercised && x.optionsTotal > 0)) && (this.viewVotingShareholdersOnly == false || x.shares.some((x) => x.shareClass.canVote))
      );
    },
    optionHolders(): Array<StakeholderDto> {
      return this.rawDataHolders.filter((x) => x.optionsTotal > 0);
    },
    shareholdersTableData(): Array<ShareholderTableRecord> {
      return this.shareholders.map((x) => ({
        stakeholderName: x.name,
        stakeholderId: x.id,
        shares: this.getShares(x),
        ownershipPercent: (this.getShares(x) / this.companyShares) * 100,
        canVote: x.shares.some((x) => x.shareClass.canVote),
      }));
    },
    optionHoldersTableData(): Array<OptionHolderTableRecord> {
      return this.optionHolders.map((x) => ({
        stakeholderName: x.name,
        stakeholderId: x.id,
        options: x.optionsTotal,
        ownershipPercent: (x.optionsTotal / this.companyShares) * 100,
      }));
    },
    tableDataSharesPerClass(): Array<SharesTableRecord> {
      var sharesPerClass = [] as Array<{
        shareClass: ShareClassDto;
        sharesSet: Array<{ count: number }>;
      }>;

      this.shareholders.forEach((x) => {
        x.shares.forEach((s) => {
          var record = sharesPerClass.find(
            (y) => y.shareClass.id == s.shareClass.id
          );
          if (!record) {
            record = {
              shareClass: s.shareClass,
              sharesSet: [],
            };

            sharesPerClass.push(record);
          }

          record.sharesSet.push(s);
        });

        if (this.simulateOptionsExercised) {
          x.options.forEach((s) => {
            var record = sharesPerClass.find(
              (y) => y.shareClass.id == s.pool.shareClass.id
            );
            if (!record) {
              record = {
                shareClass: s.pool.shareClass,
                sharesSet: [],
              };

              sharesPerClass.push(record);
            }

            record.sharesSet.push(s);
          });
        }
      });

      return sharesPerClass.map((x) => ({
        className: x.shareClass.name,
        shares: x.sharesSet.reduce((acc, x) => {
          return acc + x.count;
        }, 0),
        percent:
          (x.sharesSet.reduce((acc, x) => {
            return acc + x.count;
          }, 0) /
            this.companyShares) *
          100,
      }));
    },
    chartDataShares(): Array<ChartRecord> {
      return this.tableDataSharesPerClass.map((x) => ({
        label: x.className,
        value: x.shares,
      }));
    },
    companyShareClasses(): Array<ShareClassDto> {
      return companiesStore.state.current?.classes ?? [];
    },
  },
  async mounted() {
    if (!companiesStore.state.currentId || !companiesStore.state.current)
      return;

    try {
      this.loading = true;
      this.rawDataHolders = await getStakeholdersOfCompany(
        companiesStore.state.current.id,
        ["options", "shares"]
      );
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
  methods: {
    getShares(holder: StakeholderDto) {
      var shares = holder.shares.reduce((acc, x) => {
        return acc + x.count;
      }, 0);
      if (this.simulateOptionsExercised)
        shares += holder.options.reduce((acc, x) => {
          return acc + x.count;
        }, 0);

      return shares;
    },
    addSimulatedHolder() {
      var shareClass = companiesStore.state.current?.classes.find(
        (x) => x.id == this.newIssuance.sharesClassId
      );

      if (!shareClass) return;

      const simulatedHolder: StakeholderDto = {
        id: "",
        name: this.newIssuance.holderName,
        isBoardMember: false,
        isEmployee: false,
        type: "individual",
        vatNumber: "",
        taxOffice: "",
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          address: {
            id: "",
            country: "",
            state: "",
            city: "",
            streetAddress: "",
            postCode: "",
          },
        },
        shares: [],
        sharesTotal: this.newIssuance.sharesCount ?? 0,
        options: [],
        optionsTotal: 0,
        isCompany: false,
        crnNumber: "",
        user: {
          id: '0',
          firstName: '-',
          lastName: '-',
          role: 'stakeholder'
        }
      };

      const simulatedShareIssuance: SharesIssuanceDto = {
        stakeholderId: "",
        issueDate: new Date(),
        grantPrice: this.sharePrice,
        count: this.newIssuance.sharesCount ?? 0,
        shareClass: shareClass,
        id: "",
        status: "issued",
        directorFile: {
          name: "director.pdf",
          type: "Resolution",
          mimeType: "application/pdf",
          createdDate: new Date(),
          status : "pending"
        },
        shareholderFile: {
          name: "director.pdf",
          type: "Resolution",
          mimeType: "application/pdf",
          createdDate: new Date(),
          status : "pending"
        },
      };

      simulatedHolder.shares.push(simulatedShareIssuance);

      this.simulatedHolders.push(simulatedHolder);

      this.newIssuance.holderName = "";
      this.newIssuance.sharesCount = undefined;
      this.newIssuance.sharesClassId = "";

      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
    },
    removeSimulatedHolder(index: number) {
      this.simulatedHolders.splice(index, 1);
    },
  },
});
